
export default {
    data() {
        return {
            sideNavOpen: true,
            topNavHeight: 0
        }
    },
    computed: {
        storageUrl() {
            return process.env.STORAGE_URL;
        },
        user() {
            return this.$store.state.user;
        }
    },
    methods: {
        refresh() {
            window.location.reload(true);
        }
    },
    async mounted() {
        this.$i18n.setLocale(this.$store.state.user.language);
        const workbox = await window.$workbox;
        if (workbox) {
            workbox.addEventListener("installed", event => {
                if (event.isUpdate) {
                    this.$bvToast.show("update-toast");
                }
            });
        }
        this.$nuxt.$on("toggleSideNav", () => {
            this.sideNavOpen = !this.sideNavOpen;
        });
        this.topNavHeight = this.$getTopNavHeight();
    }
};
