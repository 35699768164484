
import "codemirror/lib/codemirror.css";
var CodeMirror;
if (process.browser) {
    CodeMirror = require("codemirror");
    require("codemirror/mode/javascript/javascript.js");
    require("codemirror/mode/htmlmixed/htmlmixed.js");
    require("codemirror/mode/xml/xml.js");
    require("codemirror/mode/css/css.js");
    require("codemirror/mode/python/python.js");
    require("codemirror/addon/edit/matchbrackets.js");
    require("codemirror/addon/edit/matchtags.js");
    require("codemirror/addon/comment/comment.js");
    require("codemirror/addon/display/placeholder.js");
}

export default {
    props: {
        value: String,
        mode: String,
        placeholder: String,
        preview: Boolean,
        text: String,
        readonly: Boolean,
        resize: {
            type: Boolean,
            default: false
        },
        allowsettings: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            editor: null,
            lastChanged: 0,
            isEditorFocused: false,
            escapePressed: false
        };
    },
    watch: {
        text(text) {
            this.setCode(text);
        },
        value(value) {
            if (this.editor.getValue() != value) {
                this.setCode(value);
            }
        }
    },
    mounted() {
        const mode = this.mode;
        const el = this.$refs.codeMirror;
        const value = this.text || this.value || "";
        el.value = value.replace(/&lt/g, "<").replace(/&gt/g, ">");
        var editor = CodeMirror.fromTextArea(el, {
            mode: mode || "htmlmixed",
            lineNumbers: true,
            lineWrapping: true,
            toggleComment: true,
            matchBrackets: true,
            screenReaderLabel: "Code editor",
            ariaLabel: "Code editor",
            tabindex: "0",
            extraKeys: {
                "Ctrl-/": function (cm) {
                    cm.toggleComment();
                },
                "Cmd-/": function (cm) {
                    cm.toggleComment();
                },
                "Shift-Esc": cm => {
                    cm.getInputField().blur();
                    this.focusRef("label");
                },
                Esc: cm => {
                    if (!this.escapePressed) {
                        this.escapePressed = true;
                        setTimeout(() => {
                            this.escapePressed = false;
                        }, 500);
                    } else {
                        cm.getInputField().blur();
                        this.focusRef("exit");
                        this.escapePressed = false;
                    }
                }
            }
        });
        if (this.readonly) {
            editor.setOption("readOnly", true);
        }
        editor.setSize(null, this.resize ? "auto" : null);

        editor.on("change", () => {
            clearTimeout(this.lastChanged);
            this.lastChanged = setTimeout(() => {
                this.$nuxt.$emit("updated");
            }, 1000);
            this.$emit("input", this.editor.getValue());
        });
        editor.on("focus", () => {
            this.isEditorFocused = true;
        });
        editor.on("blur", () => {
            this.isEditorFocused = false;
        });
        window.editor = editor;
        this.editor = editor;
        this.$nuxt.$on("editorResize", this.refresh);
        this.$emit("mounted");
    },
    methods: {
        focusRef(ref) {
            if (this.$refs[ref]) {
                this.$refs[ref].focus();
                this.isEditorFocused = false;
            }
        },
        focusEditor() {
            if (this.editor) {
                this.editor.focus();
                this.isEditorFocused = true;
            }
        },
        getCode() {
            const code = this.editor.getValue();
            this.$emit("code", code);
            return code;
        },
        setCode(code) {
            this.editor.setValue(code);
        },
        getEditor() {
            return this.editor;
        },
        refresh() {
            this.editor.refresh();
        },
        copy() {
            this.editor.save();
            const el = this.$refs.codeMirror;
            el.style.display = "block";
            el.select();
            el.setSelectionRange(0, 99999);
            document.execCommand("copy");
            el.style.display = "none";
        },
        showFile(file) {
            if (!this.editor) {
                setTimeout(() => {
                    this.showFile(file);
                }, 100);
                return;
            }

            this.editor.setValue(file.content || "");
            this.editor.setOption("readOnly", !file.id);
            this.editor.clearHistory();
        }
    },
    destroyed() {
        this.$nuxt.$off("editorResize");
    }
};
